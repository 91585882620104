button::-moz-focus-inner {
    border: 0;
}

a:focus {
    outline: none;
}

.tse-gray {
    background-color: #1f1f1f !important;
}

.bg-1 {
    /* background-color: #454545 !important; */
    background-color: #1d1d1d !important;
    color: #D9D9D9 !important;
}

.foo {
    color: #acacac !important;
}

.roboto {
    font-family: Roboto Condensed, sans-serif;
}

.roboto-condensed {
    font-family: 'Roboto Condensed', sans-serif;
}

.barlow {
    font-family: Barlow, sans-serif;
}

.dark-content {
    /* background-color: #1f1f1f; */
    background-color: #161616;
}

.content {
    /* background-color: #303030; */
    background-color: #272727;
}

.content-highlight {
    /* background-color: #303030; */
    background-color: #2c2c2c;
}

.tse-orange {
    color: #CE9100;
}

.details {
    font-size: 14px;
}

.container-fluid {
    padding: 0px;
    overflow-x: hidden;
}

.left-bordered {
    border-left: 1px solid #444444ab;
}

.right-bordered {
    border-right: 1px solid #444444ab;
}

.bottom-bordered {
    border-bottom: 1px solid #444444ab;
}

.top-bordered {
    border-top: 1px solid #444444ab;
}

.navbar-dark .navbar-nav .nav-link.active {
    color: #CE9100;
}

.form-control:focus {
    border-color: #ce900067;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(61, 35, 0, 0.426);
  }

/*
.carousel-item:before {
    -webkit-box-shadow: inset 0 0 20rem rgba(0, 0, 0, 1);
    box-shadow: inset 0 0 20rem rgba(0, 0, 0, 1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
} */
